import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';
import { useLocation } from '@reach/router';

import { ENABLE_SHIFT_TIME_RECEPTION } from '@/constants/feature-flags';
import { WEDDING_RESEPSI_TIME } from '@/constants';

export const getShiftTime = (shiftType) => {
  let shift = 'Menyesuaikan Sesi';

  if (shiftType == '1') {
    shift = 'akad';
  } else if (shiftType == '2') {
    shift = 'resepsi';
  }

  return shift;
};

/**
 * custom hooks to return shift time type
 * @return {string}
 */
function useShiftTime() {
  if (!ENABLE_SHIFT_TIME_RECEPTION) return WEDDING_RESEPSI_TIME;

  const location = useLocation();
  const shiftType = getQueryValue(location, 'shift');
  let shift = getShiftTime(shiftType);

  return shift;
}

export default useShiftTime;
